import { carrierMap } from "../components/commission-statistics/commission-statistics/carrier";

export const getCarrierInfo = (carrierId: string) => {
  return carrierMap[carrierId];
  // return carrierInfo[carrierId];
  // return carrierInfo[Object.keys(carrierInfo)[7]];
};

export const getCarrierInfoByName = (carrierName: string) => {
  const carrier = Object.values(carrierMap).find(
    carrier => carrier.name === carrierName
  );

  if (!carrier) {
    for (const c of Object.values(carrierMap)) {
      if (
        c.name
          .replace(' ', '')
          .toLowerCase()
          .includes(carrierName.split(' ')[0].toLowerCase())
      ) {
        return c;
      }
    }
  }
  return carrier;
};
